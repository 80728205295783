import React, {useEffect, useState} from 'react'
import TierCard from './TierCard'
import {Alert, Row} from 'reactstrap'
import {FiChevronDown} from 'react-icons/fi';
import {connect} from 'react-redux'
import {clearPlanAlert, getPlanByApiAndOrganization} from '../../../redux/actions/plans';
import {getApiByFriendlyName} from '../../../redux/actions/apis';
import { useFeatureValue, useFeatureIsOn } from '@growthbook/growthbook-react';

const TierCardContainer = ({api, plan, show}, props) => {
    const [arrow, setArrow] = useState('down')

    const handleArrowChange = () => {
        arrow === 'down' ? setArrow('up') : setArrow('down')
    }

    const mapDescriptionToBullets = (tier) => {
        let description = tier.description || ''
        return description.split('|').map((bullet) => <li className="pricing-card__feature" key={bullet}>{bullet}</li>)
    }

    const  renderAlertForDiscontinuedPlan = (tiers, plan) => {
        const oldButNoDiscontinueAlertNeeded = ['9942e4b0-054e-11ee-b180-850362109ba1', '9942e4b1-054e-11ee-b180-8523462109ba', '9942bda0-054e-11ee-b180-342362119ba3', '9942bda0-054e-11ee-b180-342362178ba2', '9942bda0-054e-11ee-b180-342362109ba4', '9942bda0-054e-11ee-b180-342362109ba1']
        let currentTier = tiers.find((t) => t.id === plan.tierId) || {};

        const isOldButNoDiscontinueAlertNeeded = oldButNoDiscontinueAlertNeeded.includes(currentTier.id);

        if(currentTier.isDiscontinued && !isOldButNoDiscontinueAlertNeeded){
            return (
                <Alert color={'success'} className="alert--bordered" isOpen={true}>
                    <div className={'padding5'}>
                        <span><strong>NOTE:</strong>{' The plan that you\'re currently on has been discontinued. However there is no need to worry as you can keep it for as long as you would like.'}</span>
                        <p><strong>{'Current Plan: '}</strong>{currentTier.name}</p>
                        <p><strong>{`Current Price: $${currentTier.priceMonthly}/mo`}</strong></p>
                        <p><strong>{'You get:'}</strong></p>
                        <ul>
                            {mapDescriptionToBullets(currentTier)}
                        </ul>
                    </div>
                </Alert>
            )
        }
    }

    const renderTiers = () => {
        const noTrials = useFeatureIsOn('no_trial');
        return api.tiers && api.tiers.map((tier, index) => {
            let shouldShowTier = !tier.isTrial && !tier.isDiscontinued;
            // if the tier is for an expirment check that feature to see if we should show it
            if(tier.isExperimental && tier.positiveFeatureIds){
                const anyFeaturesOn = tier.positiveFeatureIds.split(',').some((feat) => useFeatureIsOn(feat));
                shouldShowTier = anyFeaturesOn;
            }
            if (tier.negativeFeatureIds) {
                const valueOfFeat = tier.negativeFeatureIds.split(',').some((feat) => useFeatureIsOn(feat));
                shouldShowTier = !valueOfFeat;
            }
            if (tier.isDiscontinued) {
                shouldShowTier = false;
            }

            return shouldShowTier ? <TierCard key={'tier-card' + index} arrow={arrow} tier={tier} currentTierId={plan.tierId} plan={plan}/> : null;
        })
    }

    useEffect(() => {
        show ? setArrow('up') : setArrow('down')
    }, [])

    return (
        <>
            {api.tiers && renderAlertForDiscontinuedPlan(api.tiers, plan)}
            <div className={'billing--heading_container'}>
                <h3 className={'card__title'}>Tier Plans</h3>
                <div style={{display: 'flex'}}>
                    {arrow === 'down' ? (<p style={{margin: 'auto 10px'}}>Show plan details</p> ) : (<p style={{margin: 'auto 10px'}}>Hide plan details</p>)}
                    <FiChevronDown className={`billing--down_arrow ${arrow}`} onClick={() => handleArrowChange()} size={24}/>
                </div>
            </div>
            <div className={'billing--tier--container'}>
                {renderTiers()}
            </div>
        </>
    )
}



const mapStateToProps = (state) => ({
    stripeModalVisible: state.stripeModal.visible,
    freeModalVisible: state.stripeModal.freeModalVisible,
    stripeModalTier: state.stripeModal.tier,
})

const mapDispatchToProps = (dispatch) => ({
    clearPlanAlert: () => dispatch(clearPlanAlert()),
    getApiByFriendlyName: (payload) => dispatch(getApiByFriendlyName(payload)),
    getPlanByApiAndOrganization: (() => dispatch(getPlanByApiAndOrganization())),
});

export default connect(mapStateToProps, mapDispatchToProps)(TierCardContainer)
