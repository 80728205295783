import {
  HANDLE_QUALITY_CHANGE,
  HANDLE_URL_CHANGE,
  HANDLE_VIEWPORT_WIDTH_CHANGE,
  MAKE_DEBUGGER_API_CALL,
  MAKE_DEBUGGER_API_CALL_ERROR,
  MAKE_DEBUGGER_API_CALL_SUCCESS, SET_API_RESPONSE_TAB,
  SCROLL_TO_RESULTS,
  SET_API_TAB_VALUE,
  SET_DEBUGGER_FORM_ALERT,
  SET_FORCE_CACHE_UPDATE,
  SET_AUTO_PROXY,
  SET_HAS_AUTO_PROXY,
  SET_LANGUAGE_VALUE,
  SET_LAST_CALLED_URL,
  SET_PROXY,
  SET_SELECTED_ELEMENTS,
  TOGGLE_FULL_PAGE,
  TOGGLE_FULL_RENDER,
  BUILD_SNIPPET
} from '../actionTypes';

const initialState = {
  alert: null,
  apiTabValue: 'site',
  defaultOptions: {
    forceCacheUpdate: false,
    scrollToResults: false,
    hasAutoProxy: true,
    autoProxy: true,
    fullRender: {
      active: false,
      cost: 10
    },
    language: { value: 'auto', label: 'Auto - use my browser setting' },
    proxyChoice: 'noProxy',
    proxyOptions: [
      {
        name :'No Proxy',
        value: 'noProxy',
        cost: 1,
      },
      {
        name :'Good Proxy',
        value: 'use_proxy',
        cost: 10,
      },
      {
        name :'Better Premium Proxy',
        value: 'use_premium',
        cost: 20,
      },
      {
        name :'Best Superior Proxy',
        value: 'use_superior',
        cost: 30,
      }
    ],
    url: '',
  },
  loading: false,
  site: {
    label: 'OpenGraph',
    lastCalledUrl: '',
    results: null,
    tab: 'results',
    value: 'site'
  },
  scrape: {
    label: 'Scrape',
    lastCalledUrl: '',
    results: null,
    tab: 'results',
    value: 'scrape'
  },
  extract: {
    label: 'Extract',
    lastCalledUrl: '',
    options: {
      htmlElements: [ 'h1', 'h2', 'h3', 'h4', 'h5', 'p' ],
      selectedHtmlElements: [ 'h1', 'p' ]
    },
    results: null,
    tab: 'results',
    value: 'extract'
    },
  screenshot: {
    label: 'Screenshot',
    lastCalledUrl: '',
    options: {
      fullPage: false,
      imageQuality: 80,
      viewportWidth: 'md',
    },
    results: null,
    tab: 'screenshot-preview',
    value: 'screenshot'
  },
  codePreview: {
    snippet: null,
    language: null
  }
}

const debuggerForm = (state = initialState, action) => {
  switch (action.type) {
    case HANDLE_URL_CHANGE:
      return {...state, defaultOptions: { ...state.defaultOptions, url: action.newUrl }};
    case SET_LAST_CALLED_URL:
      return {...state, [action.lastCalledUrlPayload.apiTabValue]: {...state[action.lastCalledUrlPayload.apiTabValue], lastCalledUrl: action.lastCalledUrlPayload.fetchUrl }};
    case SET_API_TAB_VALUE:
      return {...state, apiTabValue: action.payload};
    case SET_API_RESPONSE_TAB:
      return {...state, [action.payload.apiTabValue]: {...state[action.payload.apiTabValue], tab: action.payload.value }};
    case SET_LANGUAGE_VALUE:
      return {...state, defaultOptions: { ...state.defaultOptions, language: action.payload }};
    case SET_PROXY:
      return {...state, defaultOptions: { ...state.defaultOptions, proxyChoice: action.payload }};
    case SET_FORCE_CACHE_UPDATE:
      return {...state, defaultOptions: { ...state.defaultOptions, forceCacheUpdate: !state.defaultOptions.forceCacheUpdate }};
    case SET_AUTO_PROXY:
      return {...state, defaultOptions: { ...state.defaultOptions, autoProxy: !state.defaultOptions.autoProxy }};
    case SET_HAS_AUTO_PROXY:
      return {...state, defaultOptions: { ...state.defaultOptions, hasAutoProxy: action.payload }};
    case SET_DEBUGGER_FORM_ALERT:
      return {...state, alert: action.payload};
    case TOGGLE_FULL_RENDER:
      return {...state, defaultOptions: { ...state.defaultOptions, fullRender: { ...state.defaultOptions.fullRender, active: !state.defaultOptions.fullRender.active} }};
    case TOGGLE_FULL_PAGE:
      return {...state, screenshot: { ...state.screenshot, options: { ...state.screenshot.options, fullPage: !state.screenshot.options.fullPage }}};
    case HANDLE_QUALITY_CHANGE:
      return {...state, screenshot: { ...state.screenshot, options: { ...state.screenshot.options, imageQuality: action.payload }}};
    case HANDLE_VIEWPORT_WIDTH_CHANGE:
      return {...state, screenshot: { ...state.screenshot, options: { ...state.screenshot.options, viewportWidth: action.payload }}};
    case SET_SELECTED_ELEMENTS:
      return {...state, extract: { ...state.extract, options: { ...state.extract.options, selectedHtmlElements: action.payload }}};
    case MAKE_DEBUGGER_API_CALL:
      return {...state, defaultOptions: { ...state.defaultOptions, scrollToResults: false }, [state.apiTabValue]: { ...state[state.apiTabValue], results: null },  loading: true };
    case MAKE_DEBUGGER_API_CALL_SUCCESS:
      return {...state, loading: false, [action.payload.apiTabValue]: {...state[action.payload.apiTabValue], results: action.payload.results}};
    case MAKE_DEBUGGER_API_CALL_ERROR:
      return {...state, loading: false, alert: action.err.message};
    case BUILD_SNIPPET:
      return {...state, codePreview: action.payload};
    case SCROLL_TO_RESULTS:
      return {...state, defaultOptions: { ...state.defaultOptions, scrollToResults: action.payload.scrollToResults }};
    default:
      return state;
  }
};


export default debuggerForm;
